import { CircularProgress } from "@suraasa/placebo-ui"
import { PropsWithChildren } from "react"

const LoadingOverlay: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="absolute left-0 top-0 z-dialog flex size-full flex-col items-center justify-center rounded-[4px] bg-[#FCFCFCAA]">
      <CircularProgress />
      {children}
    </div>
  )
}

export default LoadingOverlay
