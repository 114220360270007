import {
  Avatar,
  Container,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import { LogOut } from "iconoir-react"
import { useNavigate } from "react-router"
import { getAuthInfo } from "utils/auth"
import { buildUserName, getPlatformURL } from "utils/helpers"
import { routes } from "utils/routes"

import NavLogo from "./NavLogo"
import { NavbarProps } from "./types"

const DefaultNavbar = ({
  className,
  gutterBottom = true,
  slotEnd,
  slotStart,
}: NavbarProps) => {
  const authInfo = getAuthInfo()

  const navigate = useNavigate()

  return (
    <>
      <div
        className={clsx({
          "mb-5": gutterBottom,
        })}
      >
        <nav
          className={clsx(
            "flex max-h-[62px] items-center border-b border-onSurface-200 bg-common-white-500 py-1",
            className
          )}
        >
          <Container className="w-full">
            <div className="flex w-full flex-wrap items-center justify-between sm:flex-nowrap">
              <NavLogo
                href={getPlatformURL("learn", "/")}
                slotStart={slotStart}
              />

              {/* End */}
              <div className="flex items-center gap-1">{slotEnd}</div>
              {authInfo && (
                <div>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <IconButton size="md" type="button">
                        <Avatar
                          color="two"
                          name={buildUserName(authInfo.user)}
                        />
                      </IconButton>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="text-end">
                      <DropdownMenuItem
                        color="critical"
                        startAdornment={<LogOut />}
                        onClick={() => {
                          navigate(routes.logout)
                        }}
                      >
                        Sign out
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              )}
            </div>
          </Container>
        </nav>
      </div>
    </>
  )
}

export default DefaultNavbar
