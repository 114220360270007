import { Typography } from "@suraasa/placebo-ui"

type Props = {
  image: string
  title: string
  description: string
}

const SlideLayout = ({ description, image, title }: Props) => {
  return (
    <div className="flex size-full flex-col items-center justify-center px-8 py-11 xl:px-11">
      <div className="mb-4 flex max-h-[390px] w-full justify-center xl:max-h-[600px] [&>img]:w-full [&>img]:object-contain xl:[&>img]:w-[95%]">
        <img
          alt={title}
          src={image}
          style={{ maxWidth: "580px" }}
          width="100%"
        />
      </div>
      <div>
        <Typography className="mb-1.5 text-interactive-500" variant="title2">
          {title}
        </Typography>
        <Typography className="text-muted" variant="body">
          {description}
        </Typography>
      </div>
    </div>
  )
}

export default SlideLayout
