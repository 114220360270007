import { Avatar, Button, Typography } from "@suraasa/placebo-ui"

type Props = {
  fullName: string
  picture: string | null | undefined
  onChange?: () => void
  id: string
}

const UserInfo = ({ fullName, picture, onChange, id }: Props) => {
  return (
    <div className="mb-1 bg-common-white-500 px-2.5 pt-2.5 shadow-[0px_6px_15px_rgba(0,0,0,0.05)] sm:mb-0 sm:p-0 sm:shadow-none">
      <Typography className="mb-1 text-title3Mobile lg:text-title3">
        {fullName}
      </Typography>
      <div className="mb-1 flex items-center rounded-lg border border-onSurface-200 bg-onSurface-50 p-1 shadow-md">
        <div>
          <Avatar name={fullName} className="size-5" src={picture} />
        </div>
        <Typography className="ml-1 overflow-hidden text-ellipsis">
          <div>{id}</div>
        </Typography>
      </div>
      {onChange && (
        <Button className="mb-2" variant="text" onClick={onChange}>
          Not you? Change
        </Button>
      )}
    </div>
  )
}
export default UserInfo
