import { PARTNER_PLATFORM_URL } from "utils/constants"

const PartnerNavLogo = () => {
  return (
    <a
      className="flex items-center gap-2 no-underline"
      href={PARTNER_PLATFORM_URL}
    >
      <img
        alt="ito-partner"
        className="hidden md:block"
        height="28"
        src="https://assets.suraasa.com/ito/logos/ito-partner-one-line.svg"
        width="400"
      />
      <img
        alt="ito-partner"
        className="hidden sm:block md:hidden"
        src="https://assets.suraasa.com/ito/logos/ito-partner-two-line.svg"
        width="225"
      />
      <img
        alt="ito-partner"
        className="sm:hidden"
        height="40"
        src="https://assets.suraasa.com/ito/logos/ito-original-mini.svg"
        width="40"
      />
    </a>
  )
}

export default PartnerNavLogo
