import { toast } from "@suraasa/placebo-ui"
import { AuthData } from "api/resources/users/types"
// import api from "api"
import { ErrorResponse } from "api/types"
import { FieldValues, UseFormSetError } from "react-hook-form"

import { Product } from "./constants"
import { GA } from "./googleAnalytics"

export function isUUIDString(str: string) {
  const UUIDv4Regex =
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
  return UUIDv4Regex.test(str)
}

// placebo-issue exported in @suraasa/placebo-ui but not merged as of now
export function convertHexToRGBA(hex: string, opacity: number) {
  const tempHex: string = hex.replace("#", "")

  const r = parseInt(tempHex.substring(0, 2), 16)
  const g = parseInt(tempHex.substring(2, 4), 16)
  const b = parseInt(tempHex.substring(4, 6), 16)

  return `rgba(${r},${g},${b},${opacity / 100})`
}

/**
 * @deprecated use mapErrors instead
 */
export const handleErrors = <T extends FieldValues>(
  setter: UseFormSetError<T>,
  { fieldErrors, message }: ErrorResponse["errors"]
) => {
  if (message) {
    toast.error(message)
  }
  if (fieldErrors) {
    for (const [k, v] of Object.entries(fieldErrors)) {
      if (v) setter(k as any, { message: v })
    }
  }
}

export function pluralize(
  word: string,
  count: number,
  {
    endsWithVowel,
    skipCount,
    plural,
  }: {
    endsWithVowel?: boolean
    skipCount?: boolean
    plural?: string
  } = {
    endsWithVowel: false,
    skipCount: false,
    plural: "",
  }
) {
  let str = `${count} `

  if (skipCount) {
    str = ""
  }

  if (plural) {
    return `${count !== 1 ? `${str}${plural}` : `${str}${word}`}`
  }

  return `${
    count !== 1 ? `${str}${word}${endsWithVowel ? "es" : "s"}` : `${str}${word}`
  }`
}

export const saveBlobAsFile = ({
  data,
  type,
  name,
}: {
  data: any
  type: string
  name: string
}) => {
  const blob = new Blob([data], { type })
  const blobData = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = blobData
  link.download = name
  link.click()
  setTimeout(() => {
    window.URL.revokeObjectURL(blobData)
  }, 100)
}

export function formatAssignmentTitle(title: string, shortTitle: string) {
  if (!shortTitle || shortTitle === title) return title

  return `${shortTitle}: ${title}`
}

export const getPlatformURL = (
  platform: "learn" | "suraasa" | "jobs",
  url: string
) => {
  switch (platform) {
    case "learn":
      return `${import.meta.env.VITE_LEARN_PLATFORM_URL}${url}`
    case "suraasa":
      return `${import.meta.env.VITE_SURAASA_PLATFORM_URL}${url}`
    case "jobs":
      return `${import.meta.env.VITE_JOBS_PLATFORM_URL}${url}`
    default:
      return url
  }
}

export function checkEnvVars(vars: string[]) {
  for (const v of vars) {
    if (!import.meta.env[v]) {
      throw new Error(`Please add ${v} to your environment variables`)
    }
  }
}

export const trackUser = (
  authInfo: {
    user: Pick<AuthData["user"], "firstName" | "lastName" | "email" | "uuid">
  },
  options?: { timeout?: number }
) => {
  try {
    setTimeout(() => {
      GA.setUserId(authInfo.user.uuid)
    }, options?.timeout || 0)
  } catch (e) {
    console.error(e)
  }
}

export const sleep = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms))

export const buildUserName = (user: {
  firstName: string
  lastName?: string | null
}) => {
  return [user.firstName, user.lastName].filter(Boolean).join(" ")
}

/**
 * @param setter setter to set errors in the form
 * @param fieldErrors errors from the API
 * @param fields Format: [BackendKey, FrontendKey] OR [key] (if both key names are same)
 */
export const mapErrors = <T extends FieldValues>(
  setter: UseFormSetError<T>,
  { fieldErrors }: ErrorResponse["errors"],
  fields: ([string, string] | [string])[]
) => {
  fields.forEach(pair => {
    if (!fieldErrors) return

    const key = pair[0]
    if (pair.length === 1) {
      if (fieldErrors[key]) {
        setter(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          key as any,
          {
            message: fieldErrors[key],
          },
          { shouldFocus: true }
        )
      }
    }
    if (pair.length === 2) {
      const frontendKey = pair[1]
      if (fieldErrors[key]) {
        setter(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          frontendKey as any,
          { message: fieldErrors[key] },
          { shouldFocus: true }
        )
      }
    }
  })
}

export const getProductName = (product: Product): string => {
  switch (product) {
    case Product.learning:
      return "learning"
    case Product.school:
      return "school"
    case Product.partner:
      return "partner"
    case Product.internal:
      return "internal"
    case Product.center:
      return "centre"
  }
}
