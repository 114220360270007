import {
  Avatar,
  Button,
  Container,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import Sidebar from "components/shared/Sidebar"
import {
  List,
  LogOut,
  Menu as MenuIcon,
  ProfileCircle,
  Settings,
} from "iconoir-react"
import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { getAuthInfo } from "utils/auth"
import { Platforms } from "utils/constants"
import { buildUserName, getPlatformURL } from "utils/helpers"
import useToggle from "utils/hooks/useToggle"
import { routes } from "utils/routes"

import NavLogo from "./NavLogo"
import { NavbarProps } from "./types"

const NAVBAR_HEIGHT = 62
const StudentNavbar = ({
  slotStart,
  slotEnd,
  className,
  gutterBottom = true,
}: NavbarProps) => {
  const navigate = useNavigate()

  const authInfo = getAuthInfo(Platforms.student)

  // TODO: We don't need sidebar anymore, might just remove it completely
  const renderSidebar = false

  const [enableShadow, setEnableShadow] = useState(false)
  const [sidebarOpen, toggleSidebar] = useToggle(false)

  const navigationRef = useRef<HTMLDivElement>(null)

  const keyDownHandler = (e: React.KeyboardEvent) => {
    // only execute if tab is pressed
    if (e.key !== "Tab") return
    if (navigationRef.current === null) return
    // here we query all focusable elements, customize as your own need
    const focusableModalElements = navigationRef.current.querySelectorAll(
      "a[href], button:not([disabled]), textarea, input, select"
    )

    const firstElement = focusableModalElements[0] as HTMLElement
    const lastElement = focusableModalElements[
      focusableModalElements.length - 1
    ] as HTMLElement

    // if going forward by pressing tab and lastElement is active shift focus to first focusable element
    if (!e.shiftKey && document.activeElement === lastElement) {
      firstElement.focus()
      return e.preventDefault()
    }

    // if going backward by pressing tab and firstElement is active shift focus to last focusable element
    if (e.shiftKey && document.activeElement === firstElement) {
      lastElement.focus()
      e.preventDefault()
    }
  }

  useEffect(() => {
    const handleShadow = () => {
      if (window.scrollY > NAVBAR_HEIGHT) {
        setEnableShadow(true)
      } else {
        setEnableShadow(false)
      }
    }
    window.addEventListener("scroll", handleShadow)
    return () => window.removeEventListener("scroll", handleShadow)
  })

  return (
    <div
      className="sticky top-0 z-navbar bg-white"
      ref={navigationRef}
      role="none"
      onKeyDown={sidebarOpen ? keyDownHandler : undefined}
    >
      <nav
        className={clsx(
          "relative z-navbar flex h-[62px] items-center border-b border-onSurface-200 bg-white py-1",
          className,
          {
            "mb-6": gutterBottom,
            "shadow-md": enableShadow,
          }
        )}
      >
        <Container className="w-full">
          <div className="flex w-full flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="flex items-center gap-2">
              {renderSidebar && (
                <IconButton color="black" onClick={() => toggleSidebar()}>
                  <MenuIcon />
                </IconButton>
              )}
              <NavLogo
                href={getPlatformURL("learn", "/")}
                slotStart={slotStart}
              />
            </div>
            <div className="flex gap-1">
              {slotEnd && <div>{slotEnd}</div>}
              {authInfo && (
                <div className="flex items-center sm:gap-1.5">
                  <Link
                    to={getPlatformURL("learn", "/dashboard/library")}
                    tabIndex={-1}
                  >
                    <Button className="hidden sm:block" variant="text">
                      My library
                    </Button>
                  </Link>

                  {authInfo && (
                    <DropdownMenu>
                      <DropdownMenuTrigger>
                        <Avatar
                          color="two"
                          className="size-5"
                          name={buildUserName(authInfo.user)}
                        />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuItem
                          startAdornment={<ProfileCircle />}
                          onClick={() => {
                            window.location.href = getPlatformURL(
                              "learn",
                              "/profile"
                            )
                          }}
                        >
                          Profile
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          startAdornment={<List />}
                          onClick={() => {
                            window.location.href = getPlatformURL(
                              "learn",
                              "/orders"
                            )
                          }}
                        >
                          Orders
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          startAdornment={<Settings />}
                          onClick={() => {
                            window.location.href = getPlatformURL(
                              "learn",
                              "/settings"
                            )
                          }}
                        >
                          Settings
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          color="critical"
                          startAdornment={<LogOut />}
                          onClick={() => {
                            navigate(routes.logout)
                          }}
                        >
                          Sign out
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  )}
                </div>
              )}
            </div>
          </div>
        </Container>
      </nav>
      {renderSidebar && <Sidebar open={sidebarOpen} toggle={toggleSidebar} />}
    </div>
  )
}

export default StudentNavbar
