import { Button, TextField, toast, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { AuthData } from "api/resources/users/types"
import SocialAuth from "components/auth/SocialAuth"
import { useFeatureToggle } from "components/FeatureToggleProvider"
import LinkWithQuery from "components/shared/LinkWithQuery"
import LoadingOverlay from "components/shared/LoadingOverlay"
import Navbar from "components/shared/Navbar"
import { ArrowRight } from "iconoir-react"
import { useEffect, useState } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-hook"
import { useForm } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"
import { trackSignupSourceOnFirebase } from "services/firebase/database"
import { getCurrentPlatform, savePlatformAuthInfo } from "utils/auth"
import { CaptchaType, Platforms } from "utils/constants"
import { GA, GA_EVENTS } from "utils/googleAnalytics"
import { getPlatformURL, mapErrors, trackUser } from "utils/helpers"
import { redirect } from "utils/hooks/useHybridLogin"
import { routes } from "utils/routes"
import { trackingService } from "utils/tracking"

const formatName = (name: string) => {
  if (name.length > 20) {
    return `${name.substring(0, 20)}...`
  }
  return name
}

type FormData = {
  firstName: string
  lastName: string
  email: string
  password1: string
  password2: string
}

type Props = {
  hideNavBar?: boolean
  signInRoute?: string
}

const SignUp = ({ hideNavBar = false, signInRoute = routes.login }: Props) => {
  const [step, setStep] = useState<"name" | "password" | "email">("name")

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const { platform, product } = getCurrentPlatform()

  const [loading, toggleLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState("")
  const [isSocialAuthLoading, setIsSocialAuthLoading] = useState(false)

  const featureToggle = useFeatureToggle()
  const socialAuthEnabled = featureToggle.socialAuth.isEnabled

  const {
    register,
    setError,
    clearErrors,
    getValues,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<FormData>()

  const handleRedirect = (code?: string) => {
    const redirectUrl = searchParams.get("redirect-url")
    if (redirectUrl) {
      const url = new URL(redirectUrl)
      if (code) url.searchParams.set("code", code)
      window.location.href = url.href
    } else {
      console.warn("> redirect-url not found. Redirecting to profile")
      redirect(product, platform)
    }
  }

  useEffect(() => {
    if (platform === Platforms.school) {
      navigate(routes.school.signUp, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform])

  const generateAuthCode = async (platformKey: Platforms) => {
    toggleLoading(true)
    setLoadingMessage("You will be redirected shortly")
    const res = await api.users.generateAuthCode({
      data: { platform: platformKey },
    })
    if (res.isSuccessful) {
      const { code } = res.data
      handleRedirect(code)
    } else {
      toggleLoading(false)
      setLoadingMessage("")
      if (res.errors.message) console.log(res.errors.message)
    }
  }

  const { executeGoogleReCaptcha } = useGoogleReCaptcha(
    import.meta.env.VITE_INVISIBLE_RECAPTCHA_KEY,
    {
      enterprise: true,
    }
  )
  const onAuthSuccess = async (data: Omit<AuthData, "platform">) => {
    savePlatformAuthInfo(platform, data)

    try {
      await trackSignupSourceOnFirebase(data.user)
    } catch (e) {
      console.error("Error tracking signup source", e)
    }

    trackUser(data)
    trackingService.initUser(data)
    trackingService.trackEvent(
      "USER_SIGNUP",
      {
        uuid: data.user.uuid,
        email: data.user.email.trim(),
        firstName: data.user.firstName.trim(),
        lastName: data.user.lastName?.trim() || null,
        platform: platform,
        source: trackingService.getSignupSource(),
        deviceType: "web",
      },
      ["WebEngage"]
    )

    GA.trackEvent(GA_EVENTS.signup, {
      source: trackingService.getSignupSource(),
    })

    if (platform) {
      await generateAuthCode(platform)
    } else {
      navigate(routes.profile)
    }
  }

  const onSubmit = handleSubmit(async formData => {
    clearErrors()
    if (step === "name") {
      setStep("email")
      return
    }
    if (step === "email") {
      setStep("password")
      return
    }

    const captcha = {
      value: await executeGoogleReCaptcha("auth"),
      type: CaptchaType.invisible,
    }

    if (!captcha) {
      toast.error("Please verify the captcha")
      return
    }

    const res = await api.users.signUp({
      data: {
        ...formData,
        captcha,
        email: formData.email.trim(),
        firstName: formData.firstName.trim(),
        lastName: formData.lastName?.trim() || null,
        token_type: 1,
        platform: platform,
        source: trackingService.getSignupSource(),
        utmParams: trackingService.getUTMParams(),
        /**
         * This is because password1 gets converted to password_1 by lodash. We don't want that.
         * So we skip transformation.
         */
        __noTransform: true,
      },
    })
    if (res.isSuccessful) {
      onAuthSuccess(res.data)
    } else {
      mapErrors(setError, res.errors, [
        ["firstName"],
        ["lastName"],
        ["email"],
        ["password1"],
        ["password2"],
      ])
      if (res.errors.fieldErrors?.invalidCaptcha) {
        toast.error("Verification failed, please try again later")
      }
      if (res.errors.fieldErrors?.email) {
        setStep("email")
      }
      if (
        res.errors.fieldErrors?.password1 ||
        res.errors.fieldErrors?.password2
      ) {
        setStep("password")
      }
      if (
        res.errors.fieldErrors?.firstName ||
        res.errors.fieldErrors?.lastName
      ) {
        setStep("name")
      }
    }
  })

  // TODO(multi-auth): See if we need this. I think we might.
  // useEffect(() => {
  //   const authInfo = getAuthInfo()
  //   if (authInfo) {
  //     handleRedirect()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const password1 = watch("password1")

  if (loading) {
    return (
      <LoadingOverlay>
        <Typography className="mt-2" variant="strong">
          {loadingMessage}
        </Typography>
      </LoadingOverlay>
    )
  }

  const emailAlreadyExists =
    errors.email?.message?.toLowerCase() ===
    "account with this email already exists"

  return (
    <div className="flex h-full flex-col">
      {!hideNavBar && (
        <Navbar gutterBottom={false} platform={platform ?? undefined} />
      )}
      <div className={"flex flex-1 flex-col"}>
        <form
          className="w-full bg-white p-2.5 sm:m-auto sm:w-[500px] sm:rounded-xl sm:p-[30px] sm:[box-shadow:1px_1px_5px_rgb(0_0_0/10%)]
"
          onSubmit={onSubmit}
        >
          {step === "name" && (
            <div>
              <Typography className="text-title1Mobile !tracking-[-0.04em] lg:text-title1">
                Sign up for Suraasa
              </Typography>
              <Typography className="mb-4 text-subtitle2Mobile !tracking-[-0.021em] text-muted lg:text-subtitle2">
                One account for everything on Suraasa.
              </Typography>
              <TextField
                errors={errors.firstName?.message}
                helperText={errors.firstName?.message}
                label="First Name"
                type="text"
                autoFocus
                {...register("firstName", {
                  required: { value: true, message: "Required" },
                  maxLength: {
                    value: 64,
                    message: "Length cannot be greater than 64",
                  },
                })}
                disabled={isSocialAuthLoading}
              />
              <TextField
                className="mt-1.5"
                errors={errors.lastName?.message}
                helperText={errors.lastName?.message}
                label="Last Name"
                type="text"
                {...register("lastName", {
                  maxLength: {
                    value: 64,
                    message: "Length cannot be greater than 64",
                  },
                })}
                disabled={isSocialAuthLoading}
              />
            </div>
          )}
          {step === "email" && (
            <div>
              <Typography className="mb-3" variant="title1">
                Alright, {formatName(getValues("firstName"))}. Now onto your
                email
              </Typography>
              <TextField
                errors={errors.email?.message}
                helperText={errors.email?.message}
                label="Email"
                type="email"
                autoFocus
                {...register("email", {
                  required: { value: true, message: "Required" },
                })}
                disabled={isSocialAuthLoading}
              />

              {emailAlreadyExists && (
                <div className="mt-2">
                  <LinkWithQuery
                    to={{
                      pathname: signInRoute,
                      search: `?email=${getValues("email")}&autoNext=true`,
                    }}
                    tabIndex={-1}
                  >
                    <Button variant="filled" className="w-full">
                      Would you like to sign in instead?
                    </Button>
                  </LinkWithQuery>
                </div>
              )}
            </div>
          )}
          {step === "password" && (
            <div>
              <Typography className="mb-3" variant="title1">
                Great! Let&apos;s set up a password.
              </Typography>
              <TextField
                autoComplete="password1"
                errors={errors.password1?.message}
                helperText={errors.password1?.message}
                label="password"
                type="password"
                autoFocus
                {...register("password1", {
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                  onChange: () => clearErrors("password1"),
                  required: { value: true, message: "Required" },
                })}
                disabled={isSocialAuthLoading}
              />
              <TextField
                autoComplete="password2"
                className="mt-2"
                errors={errors.password2?.message}
                helperText={errors.password2?.message}
                label="Confirm Password"
                type="password"
                {...register("password2", {
                  validate: value =>
                    value === password1 || "The passwords do not match",
                  required: { value: true, message: "Required" },
                })}
                disabled={isSocialAuthLoading}
              />

              <Button
                className="mt-2 w-full"
                endAdornment={<ArrowRight />}
                loading={isSubmitting}
                disabled={isSocialAuthLoading}
                type="submit"
              >
                Create Account
              </Button>
            </div>
          )}
          {step !== "password" && (
            <div className="flex flex-col">
              <Button
                className="mt-2 w-full"
                endAdornment={<ArrowRight />}
                loading={isSubmitting}
                type="submit"
                variant={emailAlreadyExists ? "outlined" : "filled"}
                disabled={isSocialAuthLoading}
              >
                Next
              </Button>
            </div>
          )}
          {socialAuthEnabled && (
            <>
              <div className="my-2 flex items-center justify-center space-x-1">
                <div className="h-px w-full border-b border-muted" />
                <Typography className="text-onSurface-600" variant="preTitle">
                  OR
                </Typography>
                <div className="h-px w-full border-b border-muted" />
              </div>
              <SocialAuth
                onAuthSuccess={onAuthSuccess}
                platform={platform}
                purpose="sign_up"
                product={product}
                setLoading={loading => {
                  setIsSocialAuthLoading(loading)
                }}
              />
            </>
          )}

          <Typography
            className="mt-2 text-muted"
            style={{ textAlign: "center" }}
            variant="smallBody"
          >
            Creating an account means you agree to our{" "}
            <a
              href={getPlatformURL("suraasa", "/terms-of-use")}
              rel="noreferrer"
              className="text-primary-500 hover:underline"
              target="_blank"
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              href={getPlatformURL("suraasa", "/privacy-policy")}
              rel="noreferrer"
              className="text-primary-500 hover:underline"
              target="_blank"
            >
              Privacy Policy
            </a>
          </Typography>
          {step !== "password" && (
            <div className="flex flex-col">
              <div className="mt-2 flex flex-wrap items-center justify-center">
                <Typography className="text-muted" variant="smallBody">
                  Already have an account?
                </Typography>
                <LinkWithQuery to={signInRoute} tabIndex={-1}>
                  <Button
                    className="ms-[2px] text-strongSmallBody text-black underline underline-offset-[5px]"
                    variant="text"
                  >
                    Sign in instead
                  </Button>
                </LinkWithQuery>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

export default SignUp
