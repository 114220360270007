import { Button, Container, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { Link, useMatch } from "react-router-dom"
import { SCHOOL_PLATFORM_URL } from "utils/constants"
import { routes } from "utils/routes"

import NavLogo from "./NavLogo"
import { NavbarProps } from "./types"

const SchoolNavbar = ({
  className,
  gutterBottom = true,
  slotEnd,
  slotStart,
}: NavbarProps) => {
  const matchLoginRoute = useMatch({
    path: routes.school.login,
  })
  const matchSignUpRoute = useMatch({
    path: routes.school.signUp,
  })

  return (
    <>
      <div
        className={clsx({
          "mb-5": gutterBottom,
        })}
      >
        <nav
          className={clsx(
            "flex h-[62px] max-h-[62px] items-center border-b border-onSurface-200 bg-common-white-500 py-1",
            className
          )}
        >
          <Container className="w-full">
            <div className="flex w-full flex-wrap items-center justify-between sm:flex-nowrap">
              <NavLogo href={SCHOOL_PLATFORM_URL} slotStart={slotStart} />

              {/* End */}
              <div className="flex items-center gap-1">
                <>
                  {matchLoginRoute && (
                    <div className="flex">
                      <Typography>
                        <span className="hidden sm:inline-block">
                          Don&apos;t have a Suraasa account?&nbsp;
                        </span>
                        <Link to={routes.school.signUp} tabIndex={-1}>
                          <Button variant="text" className="hover:underline">
                            Sign up
                          </Button>
                        </Link>
                      </Typography>
                    </div>
                  )}
                  {slotEnd && slotEnd}
                  {slotEnd
                    ? null
                    : matchSignUpRoute && (
                        <div className="flex">
                          <Typography>
                            <span className="hidden sm:inline-block">
                              Already have an account?&nbsp;
                            </span>
                            <Link to={routes.school.login} tabIndex={-1}>
                              <Button variant="text">Sign In</Button>
                            </Link>
                          </Typography>
                        </div>
                      )}
                </>
              </div>
            </div>
          </Container>
        </nav>
      </div>
    </>
  )
}

export default SchoolNavbar
