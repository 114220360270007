/* eslint-disable no-lonely-if */
import {
  Button,
  InputLabel,
  TextField,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { HybridLoginType, User } from "api/resources/users/types"
import clsx from "clsx"
import SocialAuth from "components/auth/SocialAuth"
import UserInfo from "components/auth/UserInfo"
import { useFeatureToggle } from "components/FeatureToggleProvider"
import LinkWithQuery from "components/shared/LinkWithQuery"
import LoadingOverlay from "components/shared/LoadingOverlay"
import Navbar from "components/shared/Navbar"
import ReactHelmet from "components/shared/ReactHelmet"
import ResendOTPButton from "components/shared/ResendOTPButton"
import { ArrowRight, InfoCircle } from "iconoir-react"
import metadata from "metadata.json"
import { useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import OtpInput from "react-otp-input"
import { useLocation, useNavigate } from "react-router"
import { Link, useSearchParams } from "react-router-dom"
import { trackSignupSourceOnFirebase } from "services/firebase/database"
import { getAuthRoute, getCurrentPlatform } from "utils/auth"
import { Platforms } from "utils/constants"
import { handleErrors } from "utils/helpers"
import { useHybridLogin } from "utils/hooks/useHybridLogin"
import { routes } from "utils/routes"

type FormData = {
  identifier: string
  otp?: string
  password?: string
}

export const VALID_PLATFORMS = Object.values(Platforms)

type Props = {
  onAuthCodeGen?: (code: string) => void
  signUpRoute?: string
  hideNavBar?: boolean
}

const HybridLogin = ({
  onAuthCodeGen,
  signUpRoute = routes.signUp,
  hideNavBar = false,
}: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const [banner, setBanner] = useState("")

  const [isSocialAuthLoading, setIsSocialAuthLoading] = useState(false)

  const featureToggle = useFeatureToggle()
  const socialAuthEnabled = featureToggle.socialAuth.isEnabled

  const bannerText = (location.state as { bannerText: string })?.bannerText

  const {
    register,
    setError,
    setValue,
    getValues,
    control,
    setFocus,
    handleSubmit,
    watch,
    unregister,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    defaultValues: {
      identifier:
        searchParams.get("email") || searchParams.get("phoneNumber") || "",
    },
    shouldUnregister: true,
  })

  const { platform, product } = getCurrentPlatform()

  const identifier = watch("identifier")

  const { onLoginSuccess, loading } = useHybridLogin({
    product,
    options: {
      initialLoading: true,
      onAuthCodeGen,
      processRedirect: !onAuthCodeGen,
      iframe: searchParams.get("iframe") === "true",
    },
    platform,
  })

  const [step, setStep] = useState<"identifier" | "password" | "otp">(
    "identifier"
  )

  const [otpData, setOtpData] = useState<{
    token: string
    resendAt: string
  } | null>(null)

  const [user, setUser] = useState<HybridLoginType | null>(null)
  const hasPassword = user?.hasPassword

  const getUserDetails = async (id: string) => {
    const res = await api.users.getUserDetails({
      data: {
        userId: id.trim(),
        product,
      },
    })
    if (res.isSuccessful) {
      setUser(res.data)
      if (res.data.hasPassword) {
        setStep("password")
      } else {
        setStep("otp")
      }
    } else {
      if (res.errors.message) {
        toast.error(res.errors.message)
      }
      if (res.errors.fieldErrors?.userId) {
        setError("identifier", { message: res.errors.fieldErrors.userId })
      }
      if (res.errors.fieldErrors?.countryCode) {
        setError("identifier", { message: res.errors.fieldErrors.countryCode })
      }
    }
  }

  const requestOTP = async () => {
    if (!user) {
      throw new Error("No user, returning early")
    }

    const data = otpData?.token
      ? {
          token: otpData.token,
        }
      : {
          medium: user.phoneNumber ? "sms" : "email",
          // Only applicable in case of sms
          channel: user.phoneNumber ? "both" : undefined,
          purpose: "login",
          user: user.uuid,
        }

    const res = await api.users.sendUserOTP({
      data: {
        ...data,
        // captcha,
      },
    })
    if (res.isSuccessful) {
      setOtpData(res.data)
    } else {
      if (res.errors.fieldErrors?.invalidCaptcha) {
        toast.error("Verification failed, please try again later")
        // triggerCheckboxCaptcha()
      }
      if (res.errors.message) {
        toast.error(res.errors.message)
      }

      if (res.errors.fieldErrors?.resendAt) {
        setOtpData(prev => ({
          token: res.errors.fieldErrors?.token || prev?.token || "",
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          resendAt: res.errors.fieldErrors!.resendAt,
        }))
      }
    }
  }

  const trackSource = (user: User) => {
    const source = searchParams.get("utm_source")
    if (source) trackSignupSourceOnFirebase(user)
  }

  const signIn = async (data: FormData) => {
    if (!user) return

    if (step === "password" && data.password) {
      const res = await api.users.login({
        data: {
          uuid: user.uuid,
          password: data.password,
          product,
        },
      })
      if (res.isSuccessful) {
        onLoginSuccess(res.data)
        trackSource(res.data.user)
      } else {
        handleErrors(setError, res.errors)
      }

      return
    }

    if (step === "otp" && data.otp) {
      const res = await api.users.verifyUserOTP({
        data: {
          universalToken: true,
          token: otpData?.token,
          otp: data.otp,
        },
      })
      if (res.isSuccessful) {
        if ("universal" in res.data) {
          onLoginSuccess({
            ...res.data.universal,
            user: res.data.user,
          })
          trackSource(res.data.user)
        }
      } else {
        if (res.errors.message) {
          toast.error(res.errors.message)
        }
        if (res.errors.fieldErrors?.otp) {
          handleErrors(setError, res.errors)
        }
      }
    }
  }

  const onSubmit = handleSubmit(formData => {
    if (step === "identifier") {
      setValue("password", "")
      setValue("otp", "")
      return getUserDetails(formData.identifier)
    }

    return signIn(formData)
  })

  useEffect(() => {
    /**
     * We are consuming location.state.bannerText and then deleting it from state
     * because it doesn't get deleted on refresh or route change
     */
    setBanner(bannerText)
    navigate(location.pathname + location.search, {
      replace: true,
      state: undefined,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (step === "identifier") {
      unregister("password")
      unregister("otp")
    }
    // Clear field value on step switch
    if (step === "otp") {
      unregister("password")
      setValue("password", "")
    }
    if (step === "password") {
      unregister("otp")
      setValue("otp", "")
      setFocus("password")
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  useEffect(() => {
    if (step === "otp") {
      // submitWithCaptcha(captcha => {
      //   requestOTP(captcha)
      // })()
      requestOTP()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  const formRef = useRef<HTMLFormElement | null>(null)

  useEffect(() => {
    if (searchParams.get("autoNext") === "true") {
      searchParams.delete("autoNext")
      setSearchParams(searchParams)

      setTimeout(() => {
        formRef.current?.requestSubmit()
      }, 250)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <LoadingOverlay />
  }

  return (
    <>
      <ReactHelmet data={metadata.signin} />
      <form ref={formRef} className="flex h-full flex-col" onSubmit={onSubmit}>
        {banner && (
          <div className="flex items-center bg-highlight-500 px-2 py-1.5 text-left">
            <InfoCircle
              className="hidden shrink-0 md:block"
              color="white"
              width={20}
            />
            <Typography className="ms-[8px] text-common-white-200">
              {banner}
            </Typography>
          </div>
        )}
        {!hideNavBar && <Navbar gutterBottom={false} platform={platform} />}

        <div className="flex grow">
          <div
            className={clsx(
              "w-full sm:m-auto sm:w-[500px] sm:rounded-xl sm:bg-white sm:p-[30px] sm:[box-shadow:1px_1px_5px_rgb(0_0_0/10%)]",
              {
                "p-2.5": step === "identifier",
              }
            )}
          >
            <div className={clsx(step === "identifier" ? "block" : "hidden")}>
              <Typography className="text-title1Mobile !tracking-[-0.04em] lg:text-title1">
                Sign in to Suraasa
              </Typography>
              <Typography className="mb-4 text-subtitle2Mobile !tracking-[-0.021em] text-muted lg:text-subtitle2">
                One account for everything on Suraasa.
              </Typography>
              <div className="flex justify-between">
                <InputLabel label="Email or phone number" />
                <Link
                  to={getAuthRoute({
                    search: {
                      identifier: identifier ?? "",
                    },
                    key: "forgotPassword",
                    platform,
                    product,
                  })}
                  tabIndex={-1}
                >
                  <Button
                    className="text-smallBody text-primary-500"
                    variant="text"
                  >
                    Forgot Password?
                  </Button>
                </Link>
              </div>
              <TextField
                errors={errors.identifier?.message}
                helperText={errors.identifier?.message}
                autoFocus
                {...register("identifier", {
                  required: { value: true, message: "Required" },
                })}
                disabled={isSocialAuthLoading}
              />

              <Button
                className="mt-3 w-full"
                endAdornment={<ArrowRight />}
                loading={isSubmitting}
                disabled={isSocialAuthLoading}
                type="submit"
              >
                Next
              </Button>
              {socialAuthEnabled && (
                <>
                  <div className="my-2 flex items-center justify-center space-x-1">
                    <div className="h-px w-full border-b border-muted" />
                    <Typography
                      className="text-onSurface-600"
                      variant="preTitle"
                    >
                      OR
                    </Typography>
                    <div className="h-px w-full border-b border-muted" />
                  </div>
                  <SocialAuth
                    onAuthSuccess={onLoginSuccess}
                    platform={platform}
                    product={product}
                    purpose="login"
                    setLoading={loading => {
                      setIsSocialAuthLoading(loading)
                    }}
                  />
                </>
              )}

              <div className="mt-2 flex flex-wrap items-center justify-center">
                <Typography className="mr-0.5 text-muted" variant="smallBody">
                  Don&apos;t have an account?
                </Typography>
                <LinkWithQuery to={signUpRoute} tabIndex={-1}>
                  <Button
                    className="ms-[2px] w-fit text-strongSmallBody text-black underline underline-offset-[5px]"
                    variant="text"
                  >
                    Sign up for free
                  </Button>
                </LinkWithQuery>
              </div>
            </div>

            {step === "password" && (
              <div>
                {user && (
                  <UserInfo
                    fullName={user.fullName}
                    id={getValues("identifier")}
                    picture={user?.profile?.picture}
                    onChange={() => {
                      setStep("identifier")
                    }}
                  />
                )}
                <div className="p-2.5 sm:p-0">
                  <div className="flex items-center justify-between">
                    <InputLabel label="Password" />
                    <Link
                      to={getAuthRoute({
                        search: {
                          identifier: identifier ?? "",
                        },
                        key: "forgotPassword",
                        platform,
                        product,
                      })}
                      tabIndex={-1}
                    >
                      <Button
                        className="text-smallBody text-primary-500"
                        variant="text"
                      >
                        Forgot Password?
                      </Button>
                    </Link>
                  </div>

                  <TextField
                    errors={errors.password?.message}
                    helperText={errors.password?.message}
                    type="password"
                    autoFocus
                    {...register("password", {
                      required: {
                        value: true,
                        message: "Required",
                      },
                    })}
                  />
                  <Button
                    className="mt-3 w-full"
                    loading={isSubmitting}
                    type="submit"
                  >
                    Sign in
                  </Button>

                  <div className="mt-2 flex items-center justify-center">
                    <Button
                      disabled={isSubmitting}
                      variant="text"
                      onClick={() => {
                        setStep("otp")
                      }}
                    >
                      Sign in with OTP
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {step === "otp" && (
              <div>
                {user && (
                  <UserInfo
                    fullName={user.fullName}
                    id={getValues("identifier")}
                    picture={user?.profile?.picture}
                    onChange={() => {
                      setStep("identifier")
                    }}
                  />
                )}
                <div className="p-2.5 sm:p-0">
                  {otpData && (
                    <div className="mb-2">
                      {user && (
                        <Typography className="mb-3">
                          A 6-digit verification code was just sent to your{" "}
                          {user.email ? "email" : "phone number"}. Please check
                          your {user.email ? "inbox" : "messages"}.
                        </Typography>
                      )}
                      <div className="flex items-center justify-between">
                        <InputLabel label="Enter OTP" />
                        <ResendOTPButton
                          className="mb-1"
                          resendAt={otpData?.resendAt}
                          text="Resend OTP"
                          timerText="Resend in"
                          onClick={() => {
                            requestOTP()
                            // submitWithCaptcha(captcha => {
                            //   requestOTP(captcha)
                            // })()
                          }}
                        />
                      </div>
                      <Controller
                        control={control}
                        name="otp"
                        render={({ field: { onChange, ...restFields } }) => (
                          <OtpInput
                            numInputs={6}
                            onChange={(x: any) => {
                              onChange(x)
                              if (typeof x === "string" && x.length === 6) {
                                onSubmit()
                              }
                            }}
                            {...restFields}
                            containerStyle="justify-between gap-2"
                            errorStyle="border-2 border-critical-500"
                            focusStyle="outline-2 outline-interactive-400 outline-offset-1"
                            hasErrored={Boolean(errors.otp)}
                            inputStyle="h-[50px] !w-[41px] rounded-[4px] border-2 border-onSurface-500 text-title2"
                            isInputNum
                            shouldAutoFocus
                          />
                        )}
                        rules={{
                          required: { value: true, message: "Required" },
                        }}
                      />
                    </div>
                  )}
                  {Boolean(errors.otp) && (
                    <Typography
                      className="mt-1 text-critical-500"
                      variant="smallBody"
                    >
                      {errors.otp?.message}
                    </Typography>
                  )}
                  <Button
                    className="mt-3 w-full"
                    loading={isSubmitting}
                    type="submit"
                  >
                    Sign in
                  </Button>
                  {hasPassword && (
                    <div className="mt-2 flex items-center justify-center">
                      <Button
                        disabled={isSubmitting}
                        variant="text"
                        onClick={() => {
                          setStep("password")
                        }}
                      >
                        Sign in with password
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  )
}

export default HybridLogin
