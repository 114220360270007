import { Button, TextField, Typography } from "@suraasa/placebo-ui"
import AuthLayout from "components/auth/partner/AuthLayout"
import DarkOverlayLoading from "components/shared/DarkOverlayLoading"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { Platforms, Product } from "utils/constants"
import { handleErrors } from "utils/helpers"
import { useLogin } from "utils/hooks/useLogin"
import { routes } from "utils/routes"

type FormData = {
  email: string
  password: string
}

type Props = {
  onSuccess?: (code: string) => void
}

const PartnerLogin = ({ onSuccess }: Props) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormData>()

  const { handleSubmit: onSubmit, loading } = useLogin({
    options: {
      initialLoading: true,
      processRedirect: !onSuccess,
    },
    product: Product.partner,
    platform: Platforms.olympiadPartner,
    onSuccess,
  })

  const submitForm = handleSubmit(async data => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const errors = await onSubmit(data)
    if (errors) {
      handleErrors(setError, errors)
    }
  })

  return (
    <DarkOverlayLoading
      enabled={loading}
      message={<Typography variant="largeTitle">Authenticating</Typography>}
      size="lg"
    >
      <AuthLayout>
        <div className="flex flex-col">
          <form
            className="mx-auto mt-3 flex w-full max-w-full flex-col gap-2 sm:w-[358px]"
            onSubmit={submitForm}
          >
            <TextField
              errors={errors.email?.message}
              helperText={errors.email?.message}
              label="Email Address"
              type="email"
              {...register("email", {
                required: { value: true, message: "Required" },
              })}
            />
            <TextField
              autoComplete="password"
              errors={errors.password?.message}
              helperText={errors.password?.message}
              label="Password"
              type="password"
              {...register("password", {
                required: { value: true, message: "Required" },
              })}
            />
            <Button
              className="mt-3 w-full"
              loading={isSubmitting}
              type="submit"
            >
              Sign in
            </Button>
          </form>
          <div className="mt-2 flex justify-center">
            <Link to={routes.partner.forgotPassword} tabIndex={-1}>
              <Button variant="text">Forgot Password</Button>
            </Link>
          </div>
        </div>
      </AuthLayout>
    </DarkOverlayLoading>
  )
}

export default PartnerLogin
