import { CircularProgress } from "@suraasa/placebo-ui"
import { PropsWithChildren } from "react"

type Props = {
  enabled?: boolean
  message?: JSX.Element | string
  size?: "sm" | "md" | "lg"
}

const DarkOverlayLoading: React.FC<PropsWithChildren<Props>> = ({
  children,
  message,
  enabled = true,
  size = "md",
}) => {
  if (!enabled) return <>{children}</>

  return (
    <>
      {children}
      <div className="absolute left-0 top-0 z-dialog flex size-full items-center justify-center rounded-[4px] bg-[rgba(0,0,0.7)] !text-white">
        <CircularProgress size={size} />
        <div className="mt-2">{message}</div>
      </div>
    </>
  )
}

export default DarkOverlayLoading
