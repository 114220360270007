import { Button, Divider, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React, { useEffect } from "react"
import { createUseStyles } from "react-jss"
import { Link, NavLink } from "react-router-dom"
import { CSSTransition } from "react-transition-group"
import { getPlatformURL } from "utils/helpers"
import { ToggleValue } from "utils/hooks/useToggle"

const duration = 225
const sideBarWidth = 300
const cubicBezier = "cubic-bezier(0, 0, 0.2, 1) 0ms"
const useStyles = createUseStyles(() => ({
  root: {
    "&.enter-active": { left: 0 },
    "&.enter-done": { left: 0 },
    "&.exit-active": { left: -sideBarWidth },
    "&.exit-done": { left: -sideBarWidth },
  },

  backdrop: {
    transition: `background-color backdrop-filter ${duration}ms ${cubicBezier}`,
    willChange: "background-color backdrop-filter",

    "&.enter-active": {
      backgroundColor: "rgba(0,0,0,0.25)",
      backdropFilter: "blur(2px)",
    },
    "&.enter-done": {
      backgroundColor: "rgba(0,0,0,0.25)",
      backdropFilter: "blur(2px)",
    },
    "&.exit-active": {
      backgroundColor: "transparent",
      backdropFilter: "blur(0px)",
    },
    "&.exit-done": {
      backgroundColor: "transparent",
      backdropFilter: "blur(0px)",
    },
  },

  sideBarElement: {
    "&:active": {
      boxShadow: "inset 0px -1px 4px rgba(0, 0, 0, 0.07)",
    },
    // react-router puts .active class to active NavLink
    "&.active": {
      color: "#1E293B",
      padding: "12px 16px",
      background: "#EFF6FF",

      "&:hover": {
        cursor: "pointer",
        background: "#DBEAFE",
      },
      "&:active": {
        boxShadow: "inset 0px -1px 4px rgba(0, 0, 0, 0.07)",
      },
    },
  },
}))

const jobsUrl = import.meta.env.VITE_JOBS_PLATFORM_URL

const sideBarItems = [
  {
    name: "Learn",
    isHidden: false,
    children: [
      {
        name: "Assessments",
        isExternal: true,
        link: getPlatformURL("learn", "/dashboard/assessments"),
      },
      {
        name: "Assignments",
        isExternal: true,
        link: getPlatformURL("learn", "/dashboard/assignments"),
      },
      {
        name: "Discussions",
        isExternal: true,
        link: getPlatformURL("learn", "/dashboard/discussions"),
      },
      {
        name: "Classrooms",
        isExternal: true,
        link: getPlatformURL("learn", "/dashboard/attendance"),
      },
    ],
  },
  {
    name: "Jobs",
    isHidden: Boolean(!jobsUrl),
    children: [
      { name: "Dashboard", isExternal: true, link: `${jobsUrl}/home` },
    ],
  },
  {
    name: "Store",
    isHidden: false,
    children: [
      {
        name: "Browse",
        isExternal: true,
        link: getPlatformURL("suraasa", "/store"),
      },
      {
        name: "Courses",
        isExternal: true,
        link: getPlatformURL("suraasa", "/store/courses"),
      },
      {
        name: "Qualifications",
        isExternal: true,
        link: getPlatformURL("suraasa", "/store/qualifications"),
      },
      {
        name: "Certifications",
        isExternal: true,
        link: getPlatformURL("suraasa", "/store/certifications"),
      },
    ],
  },
  {
    name: "Help",
    isHidden: false,
    children: [
      {
        name: "Reach us for help",
        isExternal: false,
        link: "/help",
      },
      // { name: "Change Language", isExternal: true, link: "/change language" },
    ],
  },
]

type Props = {
  open: boolean
  toggle: ToggleValue
}

const Sidebar = ({ open, toggle }: Props) => {
  const classes = useStyles()

  /**
   * Disable scrollbar, prevent content shift when sidebar opens
   */
  useEffect(() => {
    const { body } = document
    const nav = document.querySelector("nav")
    const scrollBarWidth = window.innerWidth - body.clientWidth

    if (open) {
      body.style.paddingRight = `${scrollBarWidth}px`
      if (nav) {
        nav.style.marginRight = `-${scrollBarWidth}px`
      }
      body.style.overflow = "hidden"
    } else {
      if (nav) {
        nav.style.marginRight = `0px`
      }
      body.style.paddingRight = `0px`
      body.style.overflow = "auto"
    }
  }, [open])

  useEffect(() => {
    function handleEsc(e: KeyboardEvent) {
      if (e.key === "Escape") {
        toggle(false)
      }
    }
    document.addEventListener("keydown", handleEsc)
    return () => {
      document.removeEventListener("keydown", handleEsc)
    }
  }, [toggle])

  return (
    <>
      <CSSTransition
        in={open}
        timeout={duration}
        appear
        mountOnEnter
        unmountOnExit
      >
        <div
          className={clsx(
            "absolute left-[-300px] top-[62px] z-10 h-[calc(100vh-62px)] w-[300px] overflow-y-auto bg-white transition-[left] duration-[225ms] ease-[cubic-bezier(0,0,0.2,1)]",
            classes.root
          )}
        >
          <div className="flex h-full flex-col">
            {sideBarItems
              .filter(({ isHidden }) => !isHidden)
              .map(({ name, children }) => (
                <React.Fragment key={name}>
                  <Typography
                    className="m-1.5 pb-1 pl-2 pt-3 text-onSurface-400 no-underline"
                    key={name}
                    variant="preTitle"
                  >
                    {name}
                  </Typography>
                  {children.map(item => {
                    if (item.link) {
                      if (item.isExternal) {
                        return (
                          <a
                            className={clsx(
                              "mx-1.5 my-0.25 cursor-pointer rounded-[4px] bg-white px-2 py-1.5 font-semibold text-onSurface-800 no-underline transition-all duration-[0.2s] hover:bg-onSurface-500",
                              classes.sideBarElement
                            )}
                            href={item.link}
                            key={item.name}
                            rel="noreferrer"
                          >
                            {item.name}
                          </a>
                        )
                      }
                      return (
                        <NavLink
                          className={clsx(
                            "mx-1.5 my-0.25 cursor-pointer rounded-[4px] bg-white px-2 py-1.5 font-semibold text-onSurface-800 no-underline transition-all duration-[0.2s] hover:bg-onSurface-500",
                            classes.sideBarElement
                          )}
                          key={item.name}
                          to={item.link}
                        >
                          {item.name}
                        </NavLink>
                      )
                    }
                    return null
                  })}
                </React.Fragment>
              ))}
            <div className="mt-auto">
              <Divider className="mt-1" weight="light" />
            </div>
            <Link to="https://www.suraasa.com/privacy-policy" tabIndex={-1}>
              <Button
                className="mx-2.5 mt-1 w-fit"
                color="black"
                variant="text"
              >
                Privacy Policy
              </Button>
            </Link>
            <div className="w-full px-0 py-0.5" />
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={open}
        timeout={duration}
        appear
        mountOnEnter
        unmountOnExit
      >
        <div
          className={clsx(
            "fixed top-0 z-[7] size-full bg-transparent backdrop-blur-0",
            classes.backdrop
          )}
          role="presentation"
          onClick={() => toggle(false)}
        />
      </CSSTransition>
    </>
  )
}

export default Sidebar
