import { Button, TextField, Typography } from "@suraasa/placebo-ui"
import api from "api"
import AuthLayout from "components/auth/school/AuthLayout"
import LoadingOverlay from "components/shared/LoadingOverlay"
import ReactHelmet from "components/shared/ReactHelmet"
import metadata from "metadata.json"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Link, useSearchParams } from "react-router-dom"
import { clearAuthInfo, getAuthInfo, savePlatformAuthInfo } from "utils/auth"
import { Platforms, Product, SCHOOL_PLATFORM_URL } from "utils/constants"
import { handleErrors } from "utils/helpers"
import useToggle from "utils/hooks/useToggle"
import { routes } from "utils/routes"

type FormData = {
  email: string
  password: string
}

const Login = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormData>()

  const [loading, toggleLoading] = useToggle(true)
  const [loadingMessage, setLoadingMessage] = useState("")

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get("platform") !== Platforms.school) {
      searchParams.set("platform", Platforms.school)
      setSearchParams(searchParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const openLink = (code: string) => {
    const redirectUrl = searchParams.get("redirect-url")
    if (redirectUrl) {
      const url = new URL(redirectUrl)
      url.searchParams.set("code", code)
      window.location.href = url.href
    } else {
      console.error("> redirect-url not found. Redirecting to school platform")
      const url = new URL(SCHOOL_PLATFORM_URL)
      url.searchParams.set("code", code)
      window.location.href = url.href
    }
  }

  const generateAuthCode = async () => {
    toggleLoading(true)
    setLoadingMessage("You will be redirected shortly")

    const res = await api.users.generateAuthCode({
      data: { platform: Platforms.school },
    })
    if (res.isSuccessful) {
      const { code } = res.data
      openLink(code)
    } else {
      toggleLoading(false)
      setLoadingMessage("")
      if (res.errors.message) console.log(res.errors.message)
    }
  }

  const onSubmit = handleSubmit(async data => {
    // Logout the user if they are already logged in
    if (getAuthInfo(Platforms.school)) {
      await api.users.logout()
      clearAuthInfo(Platforms.school)
    }
    const res = await api.users.login({
      data: { ...data, product: Product.school },
    })
    if (res.isSuccessful) {
      savePlatformAuthInfo(Platforms.school, res.data)
      await generateAuthCode()
    } else {
      handleErrors(setError, res.errors)
    }
  })

  useEffect(() => {
    const handleLogin = () => {
      if (getAuthInfo(Platforms.school)) {
        return generateAuthCode()
      }
      toggleLoading(false)
    }
    handleLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <LoadingOverlay>
        <Typography className="mt-2" variant="strong">
          {loadingMessage}
        </Typography>
      </LoadingOverlay>
    )
  }

  return (
    <>
      <ReactHelmet data={metadata.school.signin} />
      <AuthLayout>
        <Typography className="text-title2Mobile lg:text-title2">
          Sign In.
        </Typography>
        <Typography className="mb-4 text-subtitle2Mobile tracking-[-0.021em] text-muted lg:text-subtitle2">
          Hire Skilled Teachers for Free!
        </Typography>
        <form
          className="mx-auto mt-3 flex w-full max-w-full flex-col gap-2 sm:w-[358px]"
          onSubmit={onSubmit}
        >
          <TextField
            errors={errors.email?.message}
            helperText={errors.email?.message}
            label="email address"
            type="email"
            {...register("email", {
              required: { value: true, message: "Required" },
            })}
          />
          <TextField
            autoComplete="password"
            errors={errors.password?.message}
            helperText={errors.password?.message}
            label="password"
            type="password"
            {...register("password", {
              required: { value: true, message: "Required" },
            })}
          />
          <Button className="mt-3 w-full" loading={isSubmitting} type="submit">
            Sign in
          </Button>
        </form>
        <div className="mt-2 flex justify-center">
          <Link to={routes.school.forgotPassword} tabIndex={-1}>
            <Button className="w-fit hover:underline" variant="text">
              Forgot Password
            </Button>
          </Link>
        </div>
      </AuthLayout>
    </>
  )
}

export default Login
