import { Container, Typography } from "@suraasa/placebo-ui"
import Navbar from "components/shared/Navbar"
import { PropsWithChildren } from "react"
import { Platforms } from "utils/constants"

const PartnerAuthLayout = ({ children }: PropsWithChildren<unknown>) => {
  document.body.style.background = "white"

  return (
    <>
      <Navbar gutterBottom={false} platform={Platforms.olympiadPartner} />
      {/* TODO: check for css */}
      <div>
        <div
          className="absolute left-0 top-0 z-[-1] h-[30%] min-h-[300px] w-full bg-[#F1F5F9] bg-cover bg-no-repeat sm:min-h-full sm:w-[45%]"
          style={{
            backgroundImage: `url(/assets/partner-login.png)`,
          }}
        />
      </div>
      <Container className="relative min-h-[calc(100vh-62px)]">
        <div
          className="flex flex-col items-center md:flex-row md:justify-between"
          style={{ height: "100%" }}
        >
          <div className="z-[3] flex h-[30%] w-full flex-col justify-center gap-4 sm:h-full sm:w-[45%] md:items-start md:gap-8">
            <Typography
              className="text-center text-decorative-two-200 sm:text-left"
              variant="largeTitle"
            >
              Sign In
            </Typography>
            <Typography className="text-center text-decorative-two-200 sm:text-left">
              Enter your credentials to access your account
            </Typography>
          </div>
          <div className="mt-7 flex h-max w-full items-center justify-center sm:h-auto sm:w-[55%] md:mt-0 md:px-7">
            {children}
          </div>
        </div>
      </Container>
    </>
  )
}

export default PartnerAuthLayout
