import { Button, Container, Typography } from "@suraasa/placebo-ui"
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <footer className="border-t border-onSurface-200">
      <Container>
        <div className="flex justify-between py-4">
          <Typography>
            © {new Date().getFullYear()} Les Transformations Learning Pvt. Ltd.
          </Typography>
          <div>
            <Link to="https://www.suraasa.com/terms-of-use" tabIndex={-1}>
              <Button variant="text" className="text-muted">
                Terms
              </Button>
            </Link>
            <span className="mx-1">🞄</span>
            <Link to="https://www.suraasa.com/privacy-policy" tabIndex={-1}>
              <Button variant="text" className="text-muted">
                Privacy Policy
              </Button>
            </Link>
            <span className="mx-1">🞄</span>
            <Link to="https://www.suraasa.com/eula" tabIndex={-1}>
              <Button variant="text" className="text-muted">
                EULA
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
