import Glide from "@glidejs/glide"
import Slide1 from "assets/auth/slide1.png"
import Slide2 from "assets/auth/slide2.png"
import Slide3 from "assets/auth/slide3.png"
import clsx from "clsx"
import Navbar from "components/shared/Navbar"
import { PropsWithChildren, useEffect } from "react"
import { createUseStyles } from "react-jss"
import { Platforms } from "utils/constants"

import SlideLayout from "./SlideLayout"

const useStyles = createUseStyles(() => ({
  sliderControl: {
    "&[class*=active]": {
      background: "#3b82f6",
    },
  },
}))

const SchoolAuthLayout = ({ children }: PropsWithChildren<unknown>) => {
  const classes = useStyles()

  useEffect(() => {
    const glide = new Glide("#slider", {
      type: "slider",
      gap: 0,
      perView: 1,
      autoplay: 7000,
      hoverpause: false,
    }).mount()

    return () => {
      glide.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Navbar gutterBottom={false} platform={Platforms.school} />

      <div className="h-[calc(100vh-62px)] bg-common-white-500 md:grid md:grid-cols-5">
        <div className="col-span-2 hidden w-full flex-col justify-center overflow-hidden bg-onSurface-50 md:flex">
          <div id="slider" style={{ position: "relative", minHeight: "645px" }}>
            <div
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className="glide__track"
              data-glide-el="track"
              style={{ height: "100%" }}
            >
              {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
              <ul className="glide__slides flex" style={{ height: "100%" }}>
                <SlideLayout
                  description="After evaluating and comparing candidates, create and send offer letters directly via Suraasa."
                  image={Slide1}
                  title="Skilled Teachers"
                />
                <SlideLayout
                  description="After evaluating and comparing candidates, create and send offer letters directly via Suraasa."
                  image={Slide2}
                  title="Verified Profiles"
                />
                <SlideLayout
                  description="After evaluating and comparing candidates, create and send offer letters directly via Suraasa."
                  image={Slide3}
                  title="Hire Faster."
                />
              </ul>
            </div>
            <div
              className="absolute bottom-[2.5rem] left-1/2 flex -translate-x-1/2 gap-1.5"
              data-glide-el="controls[nav]"
            >
              <button
                aria-label="slider-nav"
                className={clsx(
                  "h-0.25 w-[35px] bg-onSurface-300 transition-all duration-100 ease-in-out",
                  classes.sliderControl
                )}
                data-glide-dir="=0"
              />
              <button
                aria-label="slider-nav"
                className={clsx(
                  "h-0.25 w-[35px] bg-onSurface-300 transition-all duration-100 ease-in-out",
                  classes.sliderControl
                )}
                data-glide-dir="=1"
              />
              <button
                aria-label="slider-nav"
                className={clsx(
                  "h-0.25 w-[35px] bg-onSurface-300 transition-all duration-100 ease-in-out",
                  classes.sliderControl
                )}
                data-glide-dir="=2"
              />
            </div>
          </div>
        </div>
        <div className="flex h-full flex-col items-center overflow-auto pt-6 sm:col-span-3 sm:pt-10 md:justify-center md:pt-0">
          <div className="w-full max-w-[380px] px-3 sm:-mt-6 sm:max-w-[405px]">
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default SchoolAuthLayout
